// billingPortal.js
export const billingPortal = async (customerID) => {
    try {
      const response = await fetch('https://api.vectorvault.io/create-stripe-portal-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ customerID: customerID }),
      });
  
      const data = await response.json();
  
      if (data.url) {
        window.open(data.url, '_blank'); // This will open the billing portal URL in a new tab
      } else {
        console.error('Failed to get the billing portal URL');
      }
    } catch (error) {
      console.error('Error:', error);
    }
};
