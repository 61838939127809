import React, { useState, useRef, useEffect, useCallback } from "react";
import './Dashboard.css';
import '../App.css';
import ChatContainer from './ftccore.js'
import JSZip from 'jszip';
import { setCookie, getCookie, removeCookie} from '../cookieUtils.js';
import VectorVault from 'vectorvault'
import { debounce } from 'lodash';
import AddToVaultSection from "../components/AddToVault.js";

function Dashboard({ Ampersandposium, setAmpersandposium, userEmail, userKey, styles, isDarkMode, AIKeyVal, setAIKeyVal, wait, isMobile, displayValue, setDisplayValue }) {
  const ChatContainerRef = useRef(null); // create a reference using the useRef hook
  const [notificationMessage, setNotificationMessage] = useState('');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [siteUrl, setSiteUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [customDataVisible, setCustomDataVisible] = useState(false);
  const [customData, setCustomData] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showDelete, setShowDelete] = useState(true);
  const [inputCode, setInputCode] = useState('');
  const [display, setDisplay] = useState(true);
  const [database, setDatabase] = useState(true);
  const [reloadData, setReloadData] = useState(false);
  const [personality, setPersonality] = useState(`Say everything like Snoop Dogg`);
  const [vaults, setVaults] = useState(() => {
    const storedVaults = getCookie('vaults');
    try {
      return storedVaults ? JSON.parse(storedVaults) : ['-'];
    } catch (e) {
      console.error("Error parsing vaults from cookie:", e);
      return ['-'];
    }
  });
  const [newVaultName, setNewVaultName] = useState(''); // useState for new vault name
  const [showCreateNewInput, setShowCreateNewInput] = useState(false);


  const fetchVaults = useCallback(async () => {
    if (!userKey) return;

    const vectorVault = new VectorVault(userEmail, Ampersandposium, userKey);
    const vaults = await vectorVault.getVaults('');
    if (vaults.length === 0 || vaults[0] !== '-') {
      setVaults(['-', 'Create New', ...vaults]);
      setCookie("vaults", JSON.stringify(["-", 'Create New', ...vaults]));
    } else {
      setVaults(vaults);
      setCookie("vaults", JSON.stringify(vaults));
    }
  }, [userEmail, Ampersandposium, userKey]);

  useEffect(() => {
    if (!wait && userKey) {
      const storedVaults = getCookie("vaults");
      if (storedVaults) {
        setVaults(JSON.parse(storedVaults));
      }
      fetchVaults();
    }
  }, [wait, userKey, fetchVaults]);

  // useEffect to update cookie when vaults list changes
  useEffect(() => {
    setCookie("vaults", JSON.stringify(vaults));
  }, [vaults]);

  // Handler for selecting a vault
  const handleVaultChange = (e) => {
    const value = e.target.value;
    if (value === 'Create New') {
      setShowCreateNewInput(true);
      setNewVaultName('');
    } else {
      setShowCreateNewInput(false);
      updateAmpersandposium(value); 
    }
  };

  // Handler for creating a new vault
  const handleCreateNewVault = () => {
    if (newVaultName) {
      const updatedVaults = [...vaults, newVaultName];
      setVaults(updatedVaults);
      updateAmpersandposium(newVaultName); 
      setShowCreateNewInput(false);
    } else {
      alert("Please enter a name for the new vault.");
    }
  };
  
  // Handle window resize
  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (reloadData && !wait && Ampersandposium) {
      updateAmpersandposium(Ampersandposium);
    }
  }, [reloadData, wait, Ampersandposium]);

  // Load Ampersandposium from cookie on component mount
  useEffect(() => {
    const storedAmpersan = getCookie("Ampersandposium");
    if (storedAmpersan && !wait) {
      updateAmpersandposium(storedAmpersan)
    }
  }, [wait]);

  useEffect(() => {
    if (!wait && Ampersandposium) {
      fetchPersonality()
    }
  }, [reloadData, wait, Ampersandposium]);

  const updateAmpersandposium = debounce((value) => {
    setAmpersandposium(value);
    setReloadData(prevState => !prevState); // Reload data
    removeCookie("Ampersandposium");
    setCookie("Ampersandposium", value);
  }); 
  
  const Notification = ({ message }) => {
    // Effect to automatically hide the notification after 5 seconds
    useEffect(() => {
      let timeoutId;
      if ( notificationMessage ) {
        // Set a timer to hide the notification
        timeoutId = setTimeout(() => {
          setNotificationMessage('')
        }, 5000);
      }
      
      // Clean up the timer
      return () => clearTimeout(timeoutId);
    }, [notificationMessage]);

    // Don't render the notification if it's not supposed to be shown
    if (!notificationMessage) {
        return null;
    }

    // Render the notification
    return (
      <div style={styles.notificationStyle}>
        {message}
      </div>
    );
  };

  async function extractTextFromDocx(arrayBuffer) {
      const zip = new JSZip();
      const content = await zip.loadAsync(arrayBuffer);
      const docXML = await content.files["word/document.xml"].async("text");
      const parser = new DOMParser();
      const docNode = parser.parseFromString(docXML, "application/xml");

      const nsResolver = (prefix) => {
          return {
              'w': 'http://schemas.openxmlformats.org/wordprocessingml/2006/main'
          }[prefix] || null;
      };

      const textNodeResults = docNode.evaluate('//w:t', docNode, nsResolver, XPathResult.ORDERED_NODE_SNAPSHOT_TYPE, null);

      let textContentArray = [];
      for (let i = 0; i < textNodeResults.snapshotLength; i++) {
          textContentArray.push(textNodeResults.snapshotItem(i).textContent);
      }
      
      return textContentArray.join(" ");
  }

  async function handleFileUpload(event) {
      const file = event.target.files[0];
      console.log('file type:', file.type)
      setIsLoading(true);

      const reader = new FileReader();

      reader.onload = async function(event) {
        let fileContent;

        switch(file.type) {
            case 'text/plain': // .txt
            case 'text/csv':   // .csv
            case 'text/markdown': // .md
              fileContent = event.target.result;
              break;
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': // .docx
                fileContent = await extractTextFromDocx(event.target.result);
                break;
            default:
                setIsLoading(false);
                alert("Unsupported file type.");
                return;
        }

        if (checkAIKey()) {
          const vectorVault = new VectorVault(userEmail, Ampersandposium, userKey, AIKeyVal);
          await vectorVault.addCloud({ text : fileContent, name: file.name })
        .then(response => response.json())
        .then(data => {
            console.log(data);
        })
        .catch(error => {
            console.error("Error during fetch:", error);
        })
        .finally(() => {
            event.target.value = null;
            setIsLoading(false); // Hide spinner
            setReloadData(prevState => !prevState); // Reload data
            if (file.type.startsWith('image/')){
              alert("Text successfully extracted.")
            } else {
              alert("File uploaded successfully.")
            }
        });
      }
    };
    // Determine how to read the file based on its type
    if (file.type === 'text/plain' || file.type === 'text/csv' || file.type === 'text/markdown') {
        reader.readAsText(file);
    } else if (file.type === 'application/pdf' || 
               file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
               file.type.startsWith('image/')) {  // For all image types, including heic, jpeg, png
        reader.readAsArrayBuffer(file);
    } else {
        setIsLoading(false);
        alert("Unsupported file type.");
    }
  };

  
  async function handleCustomDataSave(customDataContent) {
      setIsLoading(true); 
      if (checkAIKey()) {
        const vectorVault = new VectorVault(userEmail, Ampersandposium, userKey, AIKeyVal);
        await vectorVault.addCloud({ text : customDataContent })
      .catch(error => {
          console.error("There was an error adding your data to the cloud:", error);
      })
      .finally(() => {
          setIsLoading(false); // Hide spinner when the fetch is complete
          setCustomData('')
          setReloadData(prevState => !prevState); // Reload data
          alert("Data uploaded successfully.")
      });
    }
  }

  function checkAIKey() {
      if (!AIKeyVal || AIKeyVal.trim() === '') {
          alert("OpenAI API key required to make request");
          return false;
      }
      return true;
  }

  async function handlePersonalitySave(personalityMessage) {
      setIsLoading(true); 
      setPersonality(personalityMessage)
      const vectorVault = new VectorVault(userEmail, Ampersandposium, userKey, AIKeyVal);
      await vectorVault.savePersonalityMessage(personalityMessage)
      alert("Personality saved successfully.") 
      setIsLoading(false); 
  }

  const fetchPersonality = useCallback(async () => {
    if (!userKey || !AIKeyVal) {  // Check if the userKey and AIKeyVal are ready
      // console.log("Skipping fetch, key or AI key not available.");
      return;
    }
    // console.log("Making fetch.");
  
    const vectorVault = new VectorVault(userEmail, Ampersandposium, userKey, AIKeyVal);
    
    try {
      const message = await vectorVault.fetchPersonalityMessage();
      setPersonality(message);
    } catch (error) {
      console.error("Error fetching personality message:", error);
    }
  }, [userEmail, Ampersandposium, userKey, AIKeyVal]); // Dependencies
  
  useEffect(() => {
    // Ensure that the fetch is only attempted when all required values are available
    if (userKey && AIKeyVal && userEmail && Ampersandposium) {
      fetchPersonality();
    }
  }, [userKey, AIKeyVal, userEmail, Ampersandposium, fetchPersonality]);
  
  
  // Update handleSiteUpload to accept siteUrl as a parameter
  async function handleSiteUpload(siteUrl) {
    setIsLoading(true);
    if (checkAIKey()) {
      const vectorVault = new VectorVault(userEmail, Ampersandposium, userKey, AIKeyVal);
      await vectorVault.addSite({ site: siteUrl, name: siteUrl })
        .then(response => response.json())
        .then(data => {
          console.log(data);
        })
        .catch(error => {
          console.error("There was an error with the fetch operation:", error);
        })
        .finally(() => {
          setIsLoading(false);
          setReloadData(prevState => !prevState);
          alert("Site uploaded successfully.");
        });
    }
  }


  const handleDelete = () => {
    setShowModal(true); // Show the modal when the delete button is clicked
  }

  async function confirmDeletion() {
    if (inputCode === "DELETE") {
      setShowModal(false);
      setIsLoading(true); // Show spinner
      const vectorVault = new VectorVault(userEmail, Ampersandposium, userKey);
      try {
        const response = await vectorVault.delete();
        console.log('delete response', response);
        updateAmpersandposium(vaults[0])
        fetchVaults()
        alert("Database deleted successfully.");
      } catch (error) {
        console.error("There was an error with the fetch operation:", error);
        alert("Error deleting database.");
      } finally {
        setIsLoading(false); // Hide spinner
        setReloadData(prevState => !prevState); // Reload data
        setInputCode(''); // Reset the input code
      }
    } else {
      alert('You must type "DELETE" to confirm you want to delete the entire database');
      setInputCode(''); // Reset the input for another try
    }
  }

  document.addEventListener("touchstart", function() {
    if (document.body.style.zoom !== "1") {
      document.body.style.zoom = 1;
    }
  });

  return (
    <div style={styles.wrapper}>
      <div className="App" style={{ ...styles.body, paddingTop: "50px" }}>
        {/* <h1 style={{ ...styles.h1, textAlign: "left", fontSize: isMobile ? "1.5rem" : "2.9rem", display: "flex", alignItems: "left", marginTop: isMobile ? "50px" : "45px"}}>
          {Ampersandposium}
        </h1> */}
        <div style={{ marginTop: "20px" }}>
          {display && (
            <>
              {!showDelete && <div style={{ marginTop: "-40px" }}></div>}
              {showDelete && (
                <>
                  <div style={{ marginTop: "-10px" }}></div>
                  {database && (
                    <>
                      <div style={{ marginTop: '15px' }}></div>
                    <h3 style={{marginBottom: "20px"}}>Vault: {Ampersandposium}</h3>
                      {showCreateNewInput ? (
                        <div>
                          <input
                            type="text"
                            className={isDarkMode ? 'sleek-input-dark' : 'sleek-input-light'}
                            value={newVaultName}
                            onChange={(e) => setNewVaultName(e.target.value)}
                            placeholder="Enter new vault name"
                          />
                          <button style={{ ...styles.btn }} onClick={handleCreateNewVault}>Create</button>
                        </div>
                      ) : (
                        <>
                          <select
                            className={isDarkMode ? 'sleek-select-dark' : 'sleek-select-light'}
                            value={Ampersandposium}
                            onChange={handleVaultChange}
                          >
                            {Array.isArray(vaults) && vaults.map(vault => (
                              <option key={vault} value={vault}>{vault}</option>
                            ))}
                          </select>
                          <button style={{ ...styles.btn, marginLeft: "-5px" }} onClick={() => handleDelete()}>Delete</button>
                        </>
                      )}
              
                      <div style={{ marginTop: "20px" }}>
                        <AddToVaultSection 
                          isDarkMode={isDarkMode}
                          handleFileUpload={handleFileUpload}
                          handleSiteUpload={handleSiteUpload}
                          handleCustomDataSave={handleCustomDataSave}
                          isMobile={isMobile}
                          styles={styles}
                        />
                      </div>

                    </>
                  )}
                  <div className="loading-spinner" style={{ display: isLoading ? 'block' : 'none' }}>
                    <svg viewBox="0 0 50 50">
                      <circle cx="25" cy="25" r="20" stroke="#007aff" strokeWidth="5" fill="none" />
                    </svg>
                  </div>
                  <div>
                  {showModal && (
                    <div className={isDarkMode ? "modall modall-dark" : "modall"}>
                      <div className={isDarkMode ? "modal-content modal-content-dark" : "modal-content"}>
                        <div className="modal-header">
                          <h3>Delete Vault</h3>
                          <button className={isDarkMode ? 'btn-dark' : 'btn-light'} onClick={() => setShowModal(false)}>Close</button>
                        </div>
                        <div className="modal-body">
                          <p>Type "DELETE" below to confirm you want to permanently delete the entire database <b>{Ampersandposium}</b>:</p>
                          <input
                          className={isDarkMode ? 'sleek-input-dark' : 'sleek-input-light'}
                            type="text"
                            value={inputCode}
                            onChange={(e) => setInputCode(e.target.value)}
                          />
                        <button onClick={confirmDeletion} style={{marginLeft: '5px'}}>Confirm</button>
                        </div>
                      </div>
                    </div>
                  )}
                    {customDataVisible && (
                      <>
                        <textarea
                          placeholder={`Type or paste something you want to add to the database...`}
                          id="chat-message"
                          rows={isMobile ? 15 : 10}
                          style={{ ...styles.textarea, marginTop: "5px", paddingRight: "6px", paddingLeft: "8px" }}
                          value={customData}
                          onChange={(e) => setCustomData(e.target.value)}
                        />
                        <button style={{ ...styles.button, marginTop: "0px", marginLeft: "0px" }} onClick={() => handleCustomDataSave(customData)}>Save to database</button>
                        <br />
                      </>
                    )}
                    <div style={{marginBottom: "-10px"}} />
                    <button className={isDarkMode ? 'btn-dark' : 'btn-light'} style={{ marginTop: "30px", marginLeft: "0px" }} onClick={() => setIsVisible(!isVisible)}>
                      {isVisible ? 'Hide' : 'Edit'} Personality
                    </button>
                    {isVisible && (
                      <>
                        <textarea
                          placeholder={personality}
                          id="chat-message"
                          rows={isMobile ? 4 : 2}
                          style={{ ...styles.textarea, marginTop: "5px", paddingRight: "6px", paddingLeft: "8px" }}
                          value={personality}
                          onChange={(e) => setPersonality(e.target.value)}
                        />
                        <button style={{ ...styles.button, marginTop: "0px", marginLeft: "1px" }} onClick={() => handlePersonalitySave(personality)}>Save Personality</button>
                      </>
                    )}
                  </div>
                  <br />
                </>
              )}
            </>
          )}
        </div>
        {notificationMessage && (
          <div style={{ marginTop: "10px" }}>
            <Notification
              message={notificationMessage}
            />
          </div>
        )}
        <ChatContainer
          isDarkMode={isDarkMode}
          ref={ChatContainerRef}
          isMobile={isMobile}
          windowWidth={windowWidth}
          Ampersandposium={Ampersandposium}
          display={display}
          reloadData={reloadData}
          showDelete={showDelete}
          userEmail={userEmail}
          userKey={userKey}
          AIKeyVal={AIKeyVal}
          setAIKeyVal={setAIKeyVal}
          wait={wait}
          styles={styles}
          setNotificationMessage={setNotificationMessage}
          displayValue={displayValue}
          setDisplayValue={setDisplayValue}
        />
      </div>
    </div>
  );
}

export default Dashboard;



