import React, { useState, useEffect } from "react";
import { lightStyles, darkStyles } from "../styles";
import ChangePasswordModal from './ChangePasswordModal';
import { billingPortal } from '../api/billingPortal';
import "../dashboard/Dashboard.css" 
import { updateSubdomainAPI } from "../api/updateSubdomain";

function Settings({ isDarkMode, userEmail, FirstName, LastName, handleLogout, userPlan, customerID, uuid, setUuid }) {
    const [firstName, setFirstName] = useState(FirstName);
    const [lastName, setLastName] = useState(LastName);
    const [isLoading, setIsLoading] = useState(false)
    const [email, setEmail] = useState(userEmail);
    const styles = isDarkMode ? darkStyles : lightStyles;
    const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
    // Safely get the plan display string
    const showPlan = typeof userPlan === 'string' && userPlan.length > 0
        ? userPlan.charAt(0).toUpperCase() + userPlan.slice(1)
        : 'Unknown';
    
    const payingCustomer = customerID !== "null";
    const handleBillingClick = async () => {
        if (!payingCustomer) {
            window.open('https://billing.vectorvault.io/b/7sIeYu1Rr15j0sU8wy', '_blank');
        } else {
            await billingPortal(customerID); // Pass the customerID to the billing portal function
        }
    };

    // ------------------- subdomain stuff ------------------- \\
    const [subdomain, setSubdomain] = useState(uuid);
    const [isEditingSubdomain, setIsEditingSubdomain] = useState(false);
    const [subdomainEditMessage, setSubdomainEditMessage] = useState('');
    const [subdomainEditError, setSubdomainEditError] = useState(false);    
  
    // Effect to clear the message after a timeout
    useEffect(() => {
        if (subdomainEditMessage) {
            const timer = setTimeout(() => {
                setSubdomainEditMessage('');
                setSubdomainEditError(false);
            }, 5000); // Message will disappear after 5 seconds

            // Cleanup function to clear the timeout if the component unmounts or the message changes
            return () => clearTimeout(timer);
        }
    }, [subdomainEditMessage]);

    const handleEditSubdomain = () => {
        setIsEditingSubdomain(true);
    };

    const handleSaveSubdomain = async () => {
        try {
            setIsLoading(true)
            const result = await updateSubdomainAPI(userEmail, subdomain);
            if (result.success) {
                setUuid(subdomain); // Update the uuid (which shows the subdomain)
                setSubdomainEditMessage(result.message);
                setSubdomainEditError(false);
            } else {
                setSubdomainEditMessage(result.message);
                setSubdomainEditError(true);
            }
        } catch (error) {
            setSubdomainEditMessage(error.message);
            setSubdomainEditError(true);
        } finally {
            setIsLoading(false)
            setIsEditingSubdomain(false);
        }
    };
    // ------------------- end subdomain stuff ------------------- \\

    const toggleChangePasswordModal = () => {
        setShowChangePasswordModal(!showChangePasswordModal);
    };

    // Handlers for saving changes
    const handleSaveChanges = () => {
        // Implement logic to save changes
    };

    return (
        <div style={{...styles.wrapper }}>
            <div className="App" style={{...styles.body, paddingTop: "50px"}}>
                <h1 style={{ marginBottom: '50px' }}>Settings</h1>
                    <h3 style={{ marginTop: "0px", marginBottom: "30px" }}>Plan: <strong>{showPlan}</strong></h3>
                <form onSubmit={handleSaveChanges} style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                    <label style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
                        First Name:
                        <input
                            type="text"
                            value={firstName}
                            onChange={e => setFirstName(e.target.value)}
                            className={isDarkMode ? 'sleek-input-api-dark' : 'sleek-input-api-light'}
                            style={{ marginTop: '5px' }}
                            readOnly
                        />
                    </label>
                    <label style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
                        Last Name:
                        <input
                            type="text"
                            value={lastName}
                            onChange={e => setLastName(e.target.value)}
                            className={isDarkMode ? 'sleek-input-api-dark' : 'sleek-input-api-light'}
                            style={{ marginTop: '5px' }}
                            readOnly
                        />
                    </label>
                    <label style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
                        Email:
                        <input
                            type="email"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            className={isDarkMode ? 'sleek-input-api-dark' : 'sleek-input-api-light'}
                            style={{ marginTop: '5px' }}
                            readOnly
                        />
                    </label>
                    {subdomainEditMessage && (
                        <div style={{ 
                            marginTop: '10px', 
                            color: subdomainEditError ? 'red' : 'green' 
                        }}>
                            {subdomainEditMessage}
                        </div>
                    )}
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '4px', marginTop: '10px' }}>
                        {isEditingSubdomain ? (
                            <div style={{ display: 'block' }}> {/* Wrap the content in a block display div */}
                            <span style={{ marginRight: '10px' }}>Subdomain:</span>
                                <>
                                    <input
                                        type="text"
                                        value={subdomain}
                                        onChange={(e) => setSubdomain(e.target.value)}
                                        className={isDarkMode ? 'sleek-input-api-dark' : 'sleek-input-api-light'}
                                        style={{ marginRight: '4px', width: '100px' }}
                                    />
                                    <a href='#' style={{ marginRight: '10px', fontSize: '1.1em', textDecoration: 'none' }}>.vectorvault.io</a>
                                    <button type="button" onClick={handleSaveSubdomain} className={isDarkMode ? 'btn-dark' : 'btn-light'}>Save</button>
                                    <button type="button" onClick={() => { setIsEditingSubdomain(false); setSubdomain(uuid); }} className={isDarkMode ? 'btn-dark' : 'btn-light'} style={{ marginLeft: '10px' }}>Cancel</button>
                                    <div className="loading-spinner" style={{ display: isLoading ? 'block' : 'none' }}>
                                        <svg viewBox="0 0 50 50">
                                        <circle cx="25" cy="25" r="20" stroke="#007aff" strokeWidth="5" fill="none" />
                                        </svg>
                                    </div>
                                </>
                                <div style={{ marginTop: '10px', fontSize: '0.9em' }}> {/* This will now definitely appear below */}
                                    The first time you edit your subdomain, it will be free. After that, a $10 change fee will be applied.
                                </div>
                            </div>
                        ) : (
                            <>
                            <span style={{ marginRight: '10px' }}>Subdomain:</span>
                                <a href='#' style={{ marginRight: '10px', fontSize: '1.2em', textDecoration: 'none' }}>
                                    {subdomain}.vectorvault.io
                                </a>
                                <div style={{ marginTop: '4px' }}>
                                    <button type="button" onClick={handleEditSubdomain} className={isDarkMode ? 'btn-dark' : 'btn-light'} >Edit</button>
                                </div>
                            </>
                        )}
                    </div>
                    <div style={{ marginTop: '10px', marginBottom: '20px' }}>
                        <a href="#" onClick={toggleChangePasswordModal}>Change Password</a>
                    </div>
                    <div style={{ marginBottom: '20px' }}>
                        <a href="#" onClick={handleBillingClick}>Billing Portal</a>
                    </div>
                    <div style={{ marginBottom: '20px' }}>
                        <a href="mailto:support@vectorvault.io">Support</a>
                    </div>
                    <div style={{ marginBottom: '20px' }}>
                        <a href="#" onClick={handleLogout}>Logout</a>
                    </div>
                    {/* <button className='button' type="submit" style={{ alignSelf: 'start' }}>Save Changes</button> */}
                </form>
                {showChangePasswordModal && (
                    <ChangePasswordModal
                        isOpen={showChangePasswordModal}
                        onClose={toggleChangePasswordModal}
                        userEmail={userEmail}
                        isDarkMode={isDarkMode}
                    />
                )}
            </div>
        </div>
    );
}

export default Settings;
