export const updateSubdomainAPI = async (userEmail, uuid) => {
    if (uuid.length < 3) {
        throw new Error('Subdomain must be at least 4 characters long');
    }

    const response = await fetch('https://deploy.vectorvault.io/update-uuid', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
            user: userEmail,
            uuid: uuid 
        }),
    });

    if (!response.ok) {
        throw new Error('Failed to update subdomain');
    }

    const data = await response.json();
    return { success: true, message: data.message || 'Subdomain updated successfully' };
};
