import React, { useState, useEffect } from 'react';
import { lightStyles, darkStyles } from '../styles';

function Usage({ isDarkMode, usage, plan, handleCredGet, userEmail }) {
    const styles = isDarkMode ? darkStyles : lightStyles
    const showPlan = typeof plan === 'string' && plan.length > 0
        ? plan.charAt(0).toUpperCase() + plan.slice(1)
        : plan;
    const [limits, setLimits] = useState({});
    const grandfatheredList = [
        'badaouimustafa85_at_gmail_dot_com_vvclient', 
        'bb76forever_at_gmail_dot_com_vvclient', 
        'bombezno_at_yahoo_dot_com_vvclient', 
        'bornid_at_ya_dot_ru_vvclient',
        'cgohel77_at_gmail_dot_com_vvclient',
        'joe_dot_russell_at_shcare_dot_net_vvclient',
        'john_at_runner_dot_one_vvclient',
        'john_at_vectorvault_dot_io_vvclient',
        'john_dot_rood_at_decision_dot_com_vvclient',
        'jskocili_at_gmail_dot_com_vvclient',
        'kelliofcali_at_gmail_dot_com_vvclient',
        'kutomanov_dot_hennadii_at_gmail_dot_com_vvclient',
        'moonsephy_at_gmail_dot_com_vvclient',
        'roodbiz_at_gmail_dot_com_vvclient',
        'steinermitch_at_zohomail_dot_com_vvclient',
        'wassayxy_at_gmail_dot_com_vvclient',
        'workoutstuff1_at_yahoo_dot_com_vvclient'
    ];
    // Function to transform the email to match the format in the grandfathered list
    function transformEmail(email) {
        return email.replace(/@/g, '_at_').replace(/\./g, '_dot_') + '_vvclient';
    }
    const transformedUserEmail = transformEmail(userEmail); // Transform the user email
    const OG = grandfatheredList.includes(transformedUserEmail); // Check if the transformed email is in the grandfathered list

    useEffect(() => {
        if (OG){

            setLimits({
                free: 2000,
                personal: 20000,
                pro: 200000,
                business: 2000000
            });

        } else {
            
            setLimits({
                pro: 100000,
                business: 1000000,
                enterprise: 100000000
            });
        }
    }, []);

    // Calculate the usage percentage
    const usagePercentage = (usage / limits[plan]) * 100;

    // Styles
    const progress = {
        progressBar: {
            marginTop: '20px',
            height: '50px', // Increased height
            backgroundColor: isDarkMode ? '#313640' : '#e0e0e0',
            borderRadius: '5px',
            overflow: 'hidden'
        },
        progress: {
            height: '100%',
            width: `${usagePercentage}%`,
            backgroundColor: '#0080FF', // Blue color for progress
            borderRadius: '5px',
            textAlign: 'center',
            fontSize: '1.2rem',
            lineHeight: '50px', // Adjusted to match the new height
            color: 'white'
        },
    };

    return (
            <div className="App" style={{ ...styles.body, paddingTop: "50px" }}>
                <h1 style={{marginTop: "50px"}}>Usage</h1>
                <div style={progress.progressBar}>
                    <div style={progress.progress}>{`${usagePercentage.toFixed(2)}%`}</div>
                </div>
                    <h3 style={{ marginTop: "20px", marginBottom: "10px" }}>Plan: <strong>{showPlan}</strong></h3>
                    <p>You have used <strong>{usage}</strong> out of <strong>{limits[plan]}</strong> requests.</p>
                    <div>
                        <a href="#" onClick={handleCredGet}>Refresh</a>
                    </div>
            </div>
    );
}

export default Usage;
