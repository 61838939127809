import { useState, useEffect } from 'react';

const Orientation = (query) => {
  const mediaQuery = window.matchMedia(query);
  const [matches, setMatches] = useState(mediaQuery.matches);

  useEffect(() => {
    const listener = () => setMatches(mediaQuery.matches);

    mediaQuery.addListener(listener);
    return () => mediaQuery.removeListener(listener);
  }, [mediaQuery]);

  return matches;
};

export default Orientation;