import React, { useState, useEffect, useCallback } from "react";
import "../dashboard/Dashboard.css" 
import sendDeployRequest from "../api/makeDeployment";
import deleteService from "../api/deleteDeployment";
import Deployments from "./DeploymentsTables";
import VectorVault from 'vectorvault'
import { setCookie, getCookie } from '../cookieUtils.js';

function Deploy({ isDarkMode, userEmail, styles, uuid, depsBrah, wait, userPlan, userKey }) {
    const [title, setTitle] = useState('');
    const [titleError, setTitleError] = useState('');
    const [description, setDescription] = useState('');
    const [vault, setVault] = useState('');
    const [forwardSlash, setForwardSlash] = useState('');
    const [chat, setChat] = useState(false);
    const [data, setData] = useState(false);
    const [edit, setEdit] = useState(false);
    const [del, setDel] = useState(false);
    const [GPT4, setGPT4] = useState(false);
    const [dark, setDark] = useState(false);
    const [change, setChange] = useState(false);
    const [context, setContext] = useState(false);
    const [BYOAPI, setBYOAPI] = useState(false);
    const [JSN, setJSN] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [vaults, setVaults] = useState(() => {
        const storedVaults = getCookie('vaults');
        try {
          return storedVaults ? JSON.parse(storedVaults) : ['-'];
        } catch (e) {
          console.error("Error parsing vaults from cookie:", e);
          return ['-'];
        }
      });

    const fetchVaults = useCallback(async () => {
        try {
            const vectorVault = new VectorVault(userEmail, vault, userKey);
            const fetchedVaults = await vectorVault.getVaults('');
            
            const updatedVaults = fetchedVaults.length === 0 || fetchedVaults[0] !== "-" 
                ? ["-", ...fetchedVaults] 
                : fetchedVaults;
    
            setVaults(updatedVaults);
    
            const stringifiedVaults = JSON.stringify(updatedVaults);
            setCookie("vaults", stringifiedVaults);
        } catch (error) {
            console.error('Error in fetchVaults:', error);
        }
    }, [userEmail, vault, userKey, setCookie]);

    useEffect(() => {
        if (userKey) {
            try {
                const storedVaults = getCookie("vaults");
                if (storedVaults) {
                    const parsedVaults = JSON.parse(storedVaults);
                    setVaults(parsedVaults);
                }
            } catch (error) {
                console.error("Error parsing stored vaults:");
            }
            fetchVaults();
        }
    }, [userKey]);
    
    const validateTitle = (value) => {
        // Regular expression to check for valid characters
        const validTitleRegex = /^[a-zA-Z0-9-\s]+$/;
        
        if (!validTitleRegex.test(value)) {
            setTitleError('Title can only contain letters, numbers, and hyphens.');
            return false;
        }
        setTitleError('');
        return true;
    };

    const handleEdit = (deployment) => {
        console.log(deployment)
        // Assuming deployment object has all these properties
        setTitle(deployment.title || '');
        setDescription(deployment.description || '');
        setVault(deployment.vault || '');
        setForwardSlash(deployment.forwardSlash || '');
        setChat(deployment.chat || false);
        setData(deployment.data || false);
        setEdit(deployment.edit || false);
        setDel(deployment.del || false);
        setGPT4(deployment.gpt4 || false);
        setDark(deployment.dark || false);
        setChange(deployment.change || false);
        setChange(deployment.context || false);
        setBYOAPI(deployment.byoapi || false);
        setJSN(deployment.json || false);
    };
    
    const handleDelete = async (title) => {
        if (window.confirm(`Are you sure you want to delete the deployment "${title}"?`)) {
            try {
                setIsLoading(true);
                const response = await deleteService(userEmail, title);
                console.log(response);
                setIsLoading(false);
                window.location.reload();
                alert(`Deployment "${title}" deleted successfully.`);
                // You might want to refresh the list of deployments here
                // For example, if you have a function to fetch deployments:
                // await fetchDeployments();
            } catch (error) {
                console.error("Error deleting deployment:", error);
                setIsLoading(false);
                alert(`Failed to delete deployment "${title}". Please try again.`);
            }
        }
    };

    const handleTitleChange = (e) => {
        const newTitle = e.target.value;
        setTitle(newTitle);
        validateTitle(newTitle);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateTitle(title)) {
            return; // Stop submission if title is invalid
        }

        if (userPlan !== 'free') {
            if (chat || data) {
                if (title && vault && forwardSlash) {
                    try {
                        setIsLoading(true)
                        const response = await sendDeployRequest(
                        userEmail, title, vault, forwardSlash, 
                        chat, data, edit, del, GPT4, dark, change, context, description, BYOAPI, JSN);

                        console.log(response);
                        setIsLoading(false)
                        alert(`Deployed successfully at url: ${response.domain} You can see this link and manage your active deployments below. (If this is your first deployment, it will take a 30 mintues for the domain to go live. After that it's instant.)`)
                        setTitle('');
                        setDescription('');
                        setVault('');
                        setForwardSlash('');
                        setChat(false);
                        setData(false);
                        setEdit(false);
                        setDel(false);
                        setGPT4(false);
                        setDark(false);
                        setChange(false);
                        setContext(false);
                        setBYOAPI(false);
                        setJSN(false);
                        setIsLoading(false);
                    } catch (error) {
                        console.error("Error in POST request:", error);
                        // Handle the error (e.g., show an error message to the user)
                    }
                } else { 
                    setIsLoading(false)
                    alert("Title, Vault, and the Forward Slash for your new URL are all required varibles that cannot be left blank")
                };
            } else { // no chat or data selected
                setIsLoading(false)
                alert("You must select an interface. Choose from 'Chat', 'Data', or both.")
            };
        } else { // on the free plan
            setIsLoading(false)
            alert('You must be a paying user to make a deployment. Click "SignUp" in the sidebar to conitnue.')
        };
    };

    return (
        <div style={{...styles.wrapper }}>
            <div className="App" style={{...styles.body, paddingTop: "50px"}}>
                <h1 style={{ marginBottom: '10px' }}>Deployments</h1>
                <p style={{ marginBottom: '30px' }}>
                Deployments are $10/mo and they make a live webstie for your vault. It gives you a link so you can share. 
                These Deployments are quick and easy ways to share the AI Agents and Chatbots you build. 
                Choose how visitors interact with your AI, and whether they should just chat with that AI, or be able to see the data too. Full control over read, write and delete. 
                If you have any questions, reach out to our support.
                </p>
                <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                    <label style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
                        Title:
                        <input
                            type="text"
                            value={title}
                            onChange={handleTitleChange}
                            className={isDarkMode ? 'sleek-input-api-dark' : 'sleek-input-api-light'}
                            style={{ marginTop: '5px' }}
                        />
                        {titleError && <span style={{ color: 'red', fontSize: '0.8em' }}>{titleError}</span>}
                    </label>
                    <label style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
                        Description:
                        <input
                            type="text"
                            value={description}
                            onChange={e => setDescription(e.target.value)}
                            className={isDarkMode ? 'sleek-input-api-dark' : 'sleek-input-api-light'}
                            style={{ marginTop: '5px' }}
                        />
                    </label>
                    <label style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
                        Vault:
                        <select
                            value={vault}
                            onChange={e => setVault(e.target.value)}
                            className={isDarkMode ? 'sleek-select-dark' : 'sleek-select-light'}
                            style={{ marginTop: '5px' }}
                        >
                            <option value="">Select a vault</option>
                            {vaults.map((v, index) => (
                                <option key={index} value={v}>{v}</option>
                            ))}
                        </select>
                    </label>
                    <label style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
                        Forward Slash:
                        <input
                            type="text"
                            value={forwardSlash}
                            onChange={e => setForwardSlash(e.target.value)}
                            className={isDarkMode ? 'sleek-input-api-dark' : 'sleek-input-api-light'}
                            style={{ marginTop: '5px' }}
                        />
                    </label>
                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '40px' }}>
                        {[
                            { key: 'chat', displayName: 'Chat', tooltip: 'Allow chat interface' },
                            { key: 'data', displayName: 'Data', tooltip: 'Allow data table interface' },
                            { key: 'edit', displayName: 'Edit', tooltip: 'Allow the adding and editing of data' },
                            { key: 'del', displayName: 'Delete', tooltip: 'Allow the deleting of data' },
                            { key: 'GPT4', displayName: 'GPT-4', tooltip: 'Use GPT4 (defaults to ChatGPT)' },
                            { key: 'dark', displayName: 'Dark Mode', tooltip: 'Use Dark mode (Defaults to light mode)' },
                            { key: 'change', displayName: 'Model Change', tooltip: 'Make toggle visible to change GPT models' },
                            { key: 'context', displayName: 'Context', tooltip: 'Check to make the "get context" switch visible' },
                            { key: 'BYOAPI', displayName: 'BYO API', tooltip: 'Make users bring their own OpenAI API key, rather than providing conversation/search usage with your saved key. (default unchecked)' },
                            { key: 'JSN', displayName: 'JSON', tooltip: 'Allow users to download/upload the entire database to/from JSON' }
                        ].map(({ key, displayName, tooltip }) => (
                            <div key={key} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginBottom: '10px' }}>
                                <label style={{ display: 'flex', alignItems: 'center' }} title={tooltip}>
                                    {displayName}:
                                    <input
                                        type="checkbox"
                                        checked={eval(key)}
                                        onChange={e => {
                                            console.log(`Setting ${key}: ${e.target.checked}`); 
                                            eval(`set${key.charAt(0).toUpperCase() + key.slice(1)}(e.target.checked)`)
                                        }}
                                        className={isDarkMode ? 'sleek-checkbox-dark' : 'sleek-checkbox-light'}
                                        style={{ marginLeft: '5px' }}
                                        title={tooltip}
                                    />
                                </label>
                            </div>
                        ))}
                    </div>
                    <button className='button' type="submit" style={{ alignSelf: 'start' }}>Deploy</button>
                    {/* Loading Spinner */}
                    <div className="loading-spinner" style={{ display: isLoading ? 'block' : 'none' }}>
                        <svg viewBox="0 0 50 50">
                            <circle cx="25" cy="25" r="20" stroke="#007aff" strokeWidth="5" fill="none" />
                        </svg>
                    </div>
                </form>
                <Deployments isDarkMode={isDarkMode} styles={styles} depsBrah={depsBrah} wait={wait} onEdit={handleEdit} onDelete={handleDelete} />
            </div>
        </div>
    );    
}

export default Deploy;
