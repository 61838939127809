import React from 'react';
import useMediaQuery from '../useMediaQuery'; 

const Footer = () => {
  const isMobile = useMediaQuery(768 * 2);

  const footerStyle = {
    backgroundColor: '#2f2f2f',
    color: '#aaa',
    fontSize: isMobile ? '1rem' : '1.5rem',
    textAlign: 'center',
    padding: isMobile ? '20px 0' : '40px 0',
    left: 0,  // Ensure footer starts from the far left
    width: '100vw', // Use viewport width to ensure full width
    bottom: 0
  };

  return (
    <footer style={footerStyle}>
      Powered by the Vector Vault Cloud
    </footer>
  );
};

export default Footer;