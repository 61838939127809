import React, { useState, useEffect } from 'react';
import '../dashboard/Dashboard.css'; 
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

// Styled Components
const StyledTable = styled.table`
  min-width: 1000px; // Ensure the table has a minimum width to enforce scrolling
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  table-layout: fixed;

  th, td {
    padding: 10px;
    font-size: 1.1rem;
    border: 1px solid ${props => props.isDarkMode ? '#333' : '#white'};
    text-align: center;
    vertical-align: middle;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; // Change from normal to nowrap to ensure text doesn't wrap
    color: ${props => props.isDarkMode ? '#ddd' : '#333'};
  }

  tr:nth-child(even) {
    background-color: ${props => props.isDarkMode ? '#2e333c' : '#f7f7f7'};
  }

  tr:hover {
    background-color: ${props => props.isDarkMode ? '#414d68' : '#eef6fc'};
  }

  th:nth-child(1), td:nth-child(1) {
    width: 20%; // Adjusted width for the 'Title' column
  }

  th:nth-child(2), td:nth-child(2) {
    width: 30%; // Adjusted width for the 'Domain' column
  }

  th:nth-child(3), td:nth-child(3) {
    width: 20%; // Adjusted width for the 'Forward Slash' column
  }

  th:nth-child(4), td:nth-child(4) {
    width: 15%; // Adjusted width for the 'Vault' column
  }

  th:nth-child(5), td:nth-child(5) {
    width: 15%; // Adjusted width for the 'Edit' column
  }

  @media (max-width: 768px) {
    th, td {
      padding: 5px;
    }

    th:nth-child(1), td:nth-child(1) {
      width: 20%;
    }

    th:nth-child(2), td:nth-child(2) {
      width: 30%; 
    }

    th:nth-child(3), td:nth-child(3) {
      width: 20%;
    }

    th:nth-child(4), td:nth-child(4) {
      width: 10%;
    }

    th:nth-child(5), td:nth-child(5) {
      width: 20%;
    }
  }
`;


const StyledTableContainer = styled.div`
    padding: 20px;
    overflow-x: auto; // Enable horizontal scrolling

    @media (max-width: 768px) {
    padding: 0px; // Reduced padding for mobile view
    }
`;

const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
  }
`;

const PaginationButton = styled.button`
  margin-right: 10px;

  @media (max-width: 768px) {
    font-size: 12px; // Smaller text on mobile
    padding: 5px; // Smaller padding on mobile
    margin-right: 5px; // Less margin on mobile
  }
`;

const PageIndicator = styled.span`
  margin-right: 10px;

  @media (max-width: 768px) {
    font-size: 14px; // Smaller text on mobile
  }
`;

const JumpToInput = styled.input`
  margin-left: 10px;

  @media (max-width: 768px) {
    font-size: 12px; // Smaller text on mobile
    padding: 5px; // Smaller padding on mobile
  }
`;

const PaginationSection = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    font-size: 14px; // Smaller text on mobile
    margin-top: 10px; // Add space between sections on mobile
  }
`;

const transformData = (incomingData) => {
  if (!incomingData) return [];
  return Object.keys(incomingData).map(key => ({
      title: incomingData[key].TITLE,
      domain: incomingData[key].DOMAIN,
      forwardSlash: incomingData[key].FORWARDSLASH,
      vault: incomingData[key].VAULT,
      description: incomingData[key].DESCRIPTION || '-',
      chat: incomingData[key].CHAT,
      data: incomingData[key].DATA,
      edit: incomingData[key].EDIT,
      del: incomingData[key].DELETE,
      gpt4: incomingData[key].GPT4,
      dark: incomingData[key].DARK,
      change: incomingData[key].CHANGE,
      byoapi: incomingData[key].BYOAPI,
      servicId: incomingData[key].FIREBASE_SERVICE_ID,
    })
  );
};

function formatTimestamp(timestamp) {
  if (!timestamp) return '';

  const date = new Date(timestamp);
  const options = { year: '2-digit', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false };
  return date.toLocaleString('en-US', options);
}

function formatUnixTimestamp(unixTimestamp) {
  const date = new Date(unixTimestamp * 1000); // Convert to milliseconds
  const options = { year: '2-digit', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false };
  return date.toLocaleString('en-US', options);
}

// Main Component
function Deployments({ isDarkMode, styles, depsBrah, wait, onEdit, onDelete }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(50);
  const [databaseData, setDatabaseData] = useState(false);
  const [displayedData, setDisplayedData] = useState([]); // Added displayedData state
  const [totalItems, setTotalItems] = useState(0);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [desiredPage, setDesiredPage] = useState(1);

  const handlePage = (newPage) => {
      setCurrentPage(newPage);
  };

  // useEffect for pagination
  useEffect(() => {
    setDatabaseData(transformData(depsBrah))
  }, [depsBrah]); 

    // useEffect for pagination
    useEffect(() => {
      if (databaseData.length > 0) {
          const start = (currentPage - 1) * itemsPerPage;
          const end = start + itemsPerPage;
          setDisplayedData(databaseData.slice(start, end));
      }
  }, [currentPage, itemsPerPage, databaseData]); // Depend on currentPage, itemsPerPage, and databaseData


  return (
      <div style={styles.wrapper}>
        <div className="App" style={{...styles.body, paddingTop: "50px"}}>
        <StyledTableContainer>
            {isDataLoading ? (
                // Render the loading spinner when isDataLoading is true
                <div className="loading-spinner" style={{ display: 'block' }}>
                    <svg viewBox="0 0 50 50">
                        <circle cx="25" cy="25" r="20" stroke="#007aff" strokeWidth="5" fill="none" />
                    </svg>
                </div>
            ) : (
                <>
                <h1 style={{marginTop: "100px"}}>
                    Deployments: <span> {databaseData.length} </span>
                </h1>
                <div style={{ overflowX: 'auto' }}>
                  <StyledTable isDarkMode={isDarkMode}>
                    <thead>
                        <tr>
                            <th>Title</th> 
                            <th>Domain</th> 
                            <th>Forward Slash</th> 
                            <th>Vault</th> 
                            <th>Edit</th> 
                        </tr>
                    </thead>
                    <tbody>
                        {displayedData.map((item, index) => ( // Use displayedData instead of databaseData
                            <tr key={index}>
                              <td>{item.title}</td>
                              <td>
                                <a href={item.domain} target="_blank" rel="noopener noreferrer">
                                  {item.domain}
                                </a>
                              </td>
                              <td>{item.forwardSlash}</td>
                                <td>{item.vault}</td>
                                <td>
                                  <button onClick={() => onEdit(item)} style={{ border: 'none', background: 'none', marginRight: '10px' }}>
                                    <FontAwesomeIcon icon={faEdit} style={{ fontSize: '15px', color: isDarkMode ? 'white' : '#333' }} />
                                  </button>
                                  <button onClick={() => onDelete(item.title)} style={{ border: 'none', background: 'none' }}>
                                    <FontAwesomeIcon icon={faTrash} style={{ fontSize: '15px', color: isDarkMode ? 'white' : '#333' }} />
                                  </button>
                                </td> {/* Edit button for each row */}
                            </tr>
                        ))}
                    </tbody>
                  </StyledTable>
                </div>
            {/* Pagination controls */}
            <PaginationContainer>
            <div>
                <PaginationButton 
                className={ isDarkMode ? 'btn-dark' : 'btn-light' }
                style={{ marginLeft: "0px" }}
                onClick={() => handlePage(Math.max(currentPage - 1, 1))}
                disabled={currentPage === 1}
                >
                Previous
                </PaginationButton>
                <PageIndicator>
                Page {currentPage} of {Math.ceil(totalItems / itemsPerPage)}
                </PageIndicator>
                <PaginationButton 
                className={ isDarkMode ? 'btn-dark' : 'btn-light' }
                style={{ marginLeft: "0px" }}
                onClick={() => handlePage(Math.min(currentPage + 1, Math.ceil(totalItems / itemsPerPage)))}
                disabled={currentPage === Math.ceil(totalItems / itemsPerPage)}
                >
                Next
                </PaginationButton>
            </div>
            <PaginationSection>
                <a style={{ marginTop: '0px' }}>Page: </a>
                <JumpToInput 
                className={ isDarkMode ? 'sleek-input-dark' : 'sleek-input-light' }
                style={{ marginTop:"-0px", maxWidth: "75px" }} 
                type="number" 
                value={desiredPage}
                onChange={e => setDesiredPage(Number(e.target.value))}
                />
                <PaginationButton 
                className={ isDarkMode ? 'btn-dark' : 'btn-light' }
                style={{ marginTop:"2px", marginRight: "0px" }} 
                onClick={() => setCurrentPage(Math.max(1, Math.min(desiredPage, Math.ceil(totalItems / itemsPerPage))))}
                >
                Go
                </PaginationButton>
            </PaginationSection>
            </PaginationContainer>
    
            {/* Bottom of Page Spacing */}
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            </>
        )}
        </StyledTableContainer>
      </div>
    </div>
  );
}


export default Deployments;
