function calculateScore(code, patterns) {
  let score = 0;

  patterns.forEach((pattern) => {
    const matches = (code.match(pattern) || []).length;
    if (matches > 0) {
      score += matches;
    }
  });

  return score;
}


function scoreCPlusPlus(code) {
    const patterns = [
        /#include\s*<[^>]+>/g,
        /\b(?:std::)?(?:cout|cin|cerr|clog)\b/g, 
        /using\s+namespace\s+\w+;/g,
        /\b(?:int|double|float|char|void)\b\s+\w+\s*(?:=\s*\w+)?\s*;?\s*\n?/g // Modified regex to include optional semicolon and newline character
    ];
    
    return calculateScore(code, patterns);
}

function scoreCSharp(code) {
    const patterns = [
      /using\s+\w+\s*\.(?:\w+\s*\.)+/g,
    ];
    const additionalPatterns = [
      /\b(?:Console\.WriteLine|Console\.ReadLine)\b/g,
      /System\.Linq;/g,
    ];
  
    let mainPatternsScore = calculateScore(code, patterns);
    let additionalPatternsScore = calculateScore(code, additionalPatterns);
    return mainPatternsScore > 0 ? mainPatternsScore + additionalPatternsScore : 0;
  }
  

function scoreSwift(code) {
    const patterns = [
      /import\s+[Ff]oundation/g,
      /import\s+UIKit/g, // Added for better detection of Swift code
    ];
    const additionalPatterns = [
      /\b(?:print|autoreleasepool|DispatchQueue)\b/g,
      /enum\s+\w+\s*:\s*[A-Za-z]+\s*\{/g,
    ];
  
    let mainPatternsScore = calculateScore(code, patterns);
    let additionalPatternsScore = calculateScore(code, additionalPatterns);
    return mainPatternsScore > 0 ? mainPatternsScore + additionalPatternsScore : 0;
  }

function scoreGo(code) {
  const patterns = [
    /package\s+\w+/g,
    /import\s*(?:\((?:\s*"[^"]+"\s*)+\)|"[^"]+")/g,
    /func\s+\w+\s*\((?:\s*\w*\s*[A-Za-z0-9_*]+(?:\s*,\s*)?)*\)\s*(?:\([A-Za-z0-9_*]+(?:\s*,\s*)?\))?\s*\{/g,
    /\b(?:var|const)\b\s+\w+\s+[A-Za-z0-9_*]+(?:\s*=\s*[A-Za-z0-9_."']+(?:\s*,\s*)?)*\s*;?\s*\n?/g,
    /\b(?:type)\b\s+\w+\s+(?:struct|interface)\b\s*\{/g, // Added pattern to match type declarations for struct and interface
    /\b(?:make|new)\b\s*\(\s*\w+\s*\)/g, // Added pattern to match make and new function calls
    /\b(?:go)\b\s+\w+\s*\((?:\s*\w*\s*[A-Za-z0-9_*]+(?:\s*,\s*)?)*\)/g, // Added pattern to match goroutine creation
    /\b\w+\s*:=\s*[A-Za-z0-9_.]+\b/g,
  ];

  return calculateScore(code, patterns);
}

function scorePython(code) {
    const patterns = [
        /def\s+\w+/g,
        /class\s+\w+/g,
        /if\s+__name__\s*==\s*['__"]__main__["']/g,
    ];

    return calculateScore(code, patterns);
}

function scorePHP(code) {
    const patterns = [
      /<\?php/g,
    ];
    const additionalPatterns = [
        /\b(?:print|autoreleasepool|DispatchQueue)\b/g,
        /enum\s+\w+\s*:\s*[A-Za-z]+\s*\{/g,
        /@IBOutlet\s+var\s+\w+:\s+\w+\s*\??\s*\{[^\}]*?\}/g, // Matches IBOutlets in Swift UI
        /@IBAction\s+func\s+\w+\(/g, // Matches IBActions in Swift UI
        /\b(?:guard|if|for|while|switch|case|default|return|throw|try|catch|else)\b/g, // Matches common control flow statements
    ];
    
  
    let mainPatternsScore = calculateScore(code, patterns);
    let additionalPatternsScore = calculateScore(code, additionalPatterns);
    return mainPatternsScore > 0 ? mainPatternsScore + additionalPatternsScore : 0;
  }  


function scoreJava(code) {
  const patterns = [
    /package\s+\w+(\.\w+)*;/g,
    /public\s+class\s+\w+/g,
    /System\.out\.println/g,
  ];

  return calculateScore(code, patterns);
}

function scoreJavaScript(code) {
    const patterns = [
        /console\.log\(/g,
        /\b(?:function|const|let|var)\b/g,
        /class\s+\w+\s*(?:extends\s+\w+\s*)?\{/g,
        /import\s+\w+\s+from\s+['"](?:\w+\/)*\w+['"]/g,
    ];
    
    return calculateScore(code, patterns);
}
    
function scoreJSON(code) {
    const patterns = [
        /^{[\s\S]*}$/g,
        /{\s*("[^"]*"\s*:\s*(?:"[^"]*"|[^\{\}\[\]]*),?\s*)*}/g, // matches JSON objects
        /"[^"]*"\s*:\s*(?:"[^"]*"|[^\{\}\[\]]*),?\s*/g, // matches JSON key-value pairs
      ];
    
    return calculateScore(code, patterns);
}

function scoreRuby(code) {
    const patterns = [
        /\bputs\b\s+\w+/g,
        /\bclass\b\s+\w+\s*(<\s*\w+\s*)?/g,
        /\bdef\b\s+\w+\s*(\((?:\s*\w+\s*,?)*\))?/g,
        /(?:\bmodule\b|\binclude\b)\s+\w+/g,
        /@\w+/g, // matches instance variables
    ];

    return calculateScore(code, patterns);
}

function scoreSQL(code) {
    const patterns = [
        /\bSELECT\b\s+(?:\bDISTINCT\b\s+)?(?:\*|(?:\w+\.?\w+\s*(?:,\s*)?)+)\s+\bFROM\b/gi,
        /(?:\bINNER\b|\bLEFT\b|\bRIGHT\b|\bFULL\b)\s+\bJOIN\b/gi,
        /\b(?:INSERT INTO|UPDATE|DELETE FROM)\b\s+\w+/gi,
        /\b(?:CREATE (?:DATABASE|TABLE)|ALTER TABLE|DROP (?:DATABASE|TABLE))\b\s+\w+/gi,
        /\bWHERE\b/gi,
    ];

    return calculateScore(code, patterns);
}

function getLanguageFromSpecification(code) {
  const lines = code.split('\n');
  const firstLine = lines[0];
  // console.log('Firstline:', firstLine)
  const languageSpecificationPattern = /^[\w\s]+$/i;
  // console.log('languageSpecificationPattern:', languageSpecificationPattern)
  const match = firstLine.match(languageSpecificationPattern);
  // console.log('match:', match)

  if (match) {
    return { 
      language: match[0].trim(), 
      code: code.substring(firstLine.length + 1).trim()
    };
  }

  return { language: null, code };
}


export function detectLanguage(code) {
  const { language, code: codeWithoutSpecification } = getLanguageFromSpecification(code);

  if (language) {
    return { language, code: codeWithoutSpecification, isSpecified: true };
  }

  const languages = [
    { name: 'C++', score: scoreCPlusPlus(code) },
    { name: 'C#', score: scoreCSharp(code) },
    { name: 'Swift', score: scoreSwift(code) },
    { name: 'Python', score: scorePython(code) },
    { name: 'PHP', score: scorePHP(code) },
    { name: 'Java', score: scoreJava(code) },
    { name: 'JavaScript', score: scoreJavaScript(code) },
    { name: 'JSON', score: scoreJSON(code) },
    { name: 'SQL', score: scoreSQL(code) },
    { name: 'Ruby', score: scoreRuby(code) },
    { name: 'Go', score: scoreGo(code) },
  ];
  
  const sortedLanguages = languages.sort((a, b) => b.score - a.score);
  const highestScoreLanguage = sortedLanguages[0];

  if (highestScoreLanguage.score === 0) {
    return { language: 'Text', code: codeWithoutSpecification, isSpecified: false };
  }

  return { language: highestScoreLanguage.name, code: codeWithoutSpecification, isSpecified: false };
}


export const getEditorMode = (code) => {
    const language = detectLanguage(code);
    if (language) {
      switch (language) {
        case 'Python':
          return 'python';
        case 'Java':
          return 'java';
        case 'JavaScript':
          return 'java';
        case 'C++':
          return 'c_cpp';
        case 'C#':
          return 'csharp';
        case 'PHP':
          return 'php'; // 
        case 'Json':
          return 'json';
        case 'Swift':
          return 'swift';
        case 'Ruby':
          return 'ruby';
        case 'SQL':
          return 'sql';
        case 'Go':
          return 'java';
        // Add more languages and their corresponding modes here
        default:
          return 'python'; // Fallback mode if no appropriate mode is found
      }
    } else {
      return 'python'; // Fallback mode if no language is detected
    }
  };
