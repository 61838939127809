export const deleteKey = async (email, api, name) => {
    const formData = new URLSearchParams();
    formData.append('email', email);
    formData.append('api_key', api);
    formData.append('api_name', name);

    const headers = {
        'Authorization': 'expected_authorization_code', // Replace with your actual authorization code
    };

    try {
        const response = await fetch('https://vv-register-etrszydrna-uc.a.run.app/delete_key', {
            method: 'POST',
            headers: headers,
            body: formData
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const responseJson = await response.json();
        return responseJson;
    } catch (error) {
        console.error("Error occurred during API call:", error);
        return { error: error.message };
    }
};

