// ChangePasswordModal.js
import React, { useState, useCallback, useEffect } from "react";
import styled from 'styled-components';
import { changePassword } from "../api/newPass";
import "../dashboard/Dashboard.css"; // Assuming this contains necessary global styles
import { lightStyles, darkStyles } from "../styles";

const Modal = styled.div`
  display: block; 
  position: fixed; 
  z-index: 1; 
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%; 
  overflow: auto; 
  background-color: rgba(0,0,0,0.4);
`;

const ModalContent = styled.div`
  background-color: ${props => props.isDarkMode ? '#333' : '#fefefe'};
  padding: 20px;
  border: 1px solid #888;
  border-radius: 8px;
  width: 80%;
  max-width: 500px;
  color: ${props => props.isDarkMode ? '#ddd' : '#333'};
  position: fixed; 
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const CloseButton = styled.span`
  color: ${props => props.isDarkMode ? '#ddd' : '#aaa'};
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
  cursor: pointer;
  margin-top: -23px;
  margin-right: -10px;

  &:hover,
  &:focus {
    color: black;
    text-decoration: none;
  }
`;

function ChangePasswordModal({ isOpen, onClose, userEmail, isDarkMode }) {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const styles = isDarkMode ? darkStyles : lightStyles

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError(null);
        setSuccess(null);

        if (newPassword !== confirmPassword) {
            setError('New passwords do not match.');
            return;
        }

        await changePassword(userEmail, currentPassword, newPassword)
        .then(() => {
            setSuccess('Password successfully changed.');
        }).catch(error => {
            setError('Failed to change password. ' + error.message);
        });
    };

    if (!isOpen) return null;

    return (
            <Modal>
                <ModalContent isDarkMode={isDarkMode} style={{ ...styles.body }}>
                    <CloseButton isDarkMode={isDarkMode} onClick={onClose}>&times;</CloseButton>
                    <h2>Change Password</h2>
                    <form onSubmit={handleSubmit}>
                        <br />
                        <label>
                            Current Password:
                            <input className={isDarkMode ? "sleek-input-api-dark" : "sleek-input-api-light"} style={{marginLeft: "8px"}} type="password" value={currentPassword} onChange={e => setCurrentPassword(e.target.value)} />
                        </label>
                        <br />
                        <br />
                        <label>
                            New Password:
                            <input className={isDarkMode ? "sleek-input-api-dark" : "sleek-input-api-light"} style={{marginLeft: "8px"}} type="password" value={newPassword} onChange={e => setNewPassword(e.target.value)} />
                        </label>
                        <br />
                        <br />
                        <label>
                            Confirm New Password:
                            <input className={isDarkMode ? "sleek-input-api-dark" : "sleek-input-api-light"} style={{marginLeft: "8px"}} type="password" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} />
                        </label>
                        <br />
                        <br />
                        {error && <div style={{ color: 'red' }}>{error}</div>}
                        {success && <div style={{ color: 'green' }}>{success}</div>}
                        <button className="button" isDarkMode={isDarkMode} type="submit">Change Password</button>
                    </form>
                </ModalContent>
            </Modal>
    );
}

export default ChangePasswordModal;
