//RoodModal.js
import React, { useRef, useState, useEffect } from 'react';
import { detectLanguage } from './detectlang.js';
import { AceEditorComponent } from './AceSpaceLight.js';
import { RenderCopyToClipboardButtonLightMode } from './copyclip.js';
import styled from 'styled-components';
import { FaEdit, FaTimes, FaSave } from 'react-icons/fa'; 
import VectorVault from 'vectorvault';

const StyledModal = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;

    .modal-content {
        background-color: #fff;
        padding: 20px;
        max-width: 80%;
        maxHeight: 80%;
        overflowY: auto;
        border-radius: 5px;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        max-height: 80vh; 
            
    }

    .modal-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between; 
        align-items: center; 
        padding-bottom: 10px;    
    }
    .modal-header-info {
        display: flex;
        flex-direction: column;
        align-items: flex-start; /* Align header info to the left */
    }

    .modal-body {
        // Style for the body section
        overflow-y: auto; // Handle overflow for long content
        flex-grow: 1;    // Allow the body to grow and fill available space
    }

    pre {
        white-space: pre-wrap;       /* Since CSS 2.1 */
        white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
        white-space: -pre-wrap;      /* Opera 4-6 */
        white-space: -o-pre-wrap;    /* Opera 7 */
        word-wrap: break-word;       /* Internet Explorer 5.5+ */
    }

    button {
        align-self: flex-start; 
        background-color: #007BFF;
        color: white;
        padding: 5px 15px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.2s;

        &:hover {
            background-color: #0056b3;
        }
    }
`;

const StyledTextarea = styled.textarea`
    box-sizing: border-box; /* Ensure padding and border are included in width/height */
    width: 100%;
    height: 500px;
    margin-bottom: 10px;
    font-size: 14px; /* Default font size for desktop */
    
    /* Media query for mobile devices */
    @media (max-width: 768px) {
        font-size: 16px; /* Font size for mobile devices */
        padding: 0; /* Adjust padding as needed */
        border: none; /* Adjust border as needed or set to 'none' */
    }
`;

// Modal Component
function Modal({ isOpen, onClose, itemId, createdDate, name, content, Ampersandposium, userEmail, userKey, AIKeyVal, styles }) {
    const modalContentRef = useRef(null);
    const [windowWidth] = useState(window.innerWidth);
    const [isCopied, setIsCopied] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [editableContent, setEditableContent] = useState(content);
    const textareaRef = useRef(null);
    const vectorVault = new VectorVault(userEmail, Ampersandposium, userKey, AIKeyVal)
    
    const copyToClipboard = () => {
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 3000);
    };
     
    // Adjust textarea height based on its content
    const adjustHeight = () => {
        const textarea = textareaRef.current;
        if (textarea) {
          // Temporarily disable scroll to get the correct scrollHeight
          textarea.style.overflowY = 'hidden';
          // Set height to 'auto' to allow it to shrink if needed
          textarea.style.height = 'auto';
          // Set the height to the larger of scrollHeight or current height, but not less than 200px
          const newHeight = Math.max(textarea.scrollHeight, textarea.clientHeight, 200);
          textarea.style.height = `${newHeight}px`;
          // Re-enable scroll if needed
          textarea.style.overflowY = '';
        }
      };

    // Adjust height on initial render if there's initial content
    useEffect(() => {
        adjustHeight();
    }, []);

    const toggleEdit = () => {
        if (!isEditing) {
            // Set the editable content when starting to edit
            setEditableContent(content);
        }
        // Toggle the editing state
        setIsEditing(!isEditing);
    };    

    const handleContentChange = (event) => {
        setEditableContent(event.target.value);
        adjustHeight();
    };

    const saveContent = async () => {
        setIsLoading(true);
        try {
            await vectorVault.editItem(itemId, editableContent);
            setIsEditing(false);
            alert("Edit has been saved. Refresh to see new content.");
        } catch (error) {
            alert("Error saving content: " + error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (modalContentRef.current && !modalContentRef.current.contains(event.target)) {
                setIsEditing(false);
                onClose();
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [onClose]);

    if (!isOpen) return null;

    function renderSegment(segment, index) {
        const ram = '```';
        const languageRegex = /^```\s*(python|javascript|js|csharp|cs|java|cpp|c|ruby|rb|go|swift|bash|sh|php|html|css|typescript|ts|r|perl|scala|kotlin|rust|sql)/;
        
        if (segment.startsWith(ram) && segment.endsWith(ram)) {
            if (languageRegex.test(segment)) {
                segment = segment.replace(languageRegex, '```'); // replace the language specifier
            }
    
          const code = segment.slice(3, -3);
          const type = detectLanguage(code)
          
          const increaseBy = 1/(windowWidth/500) * 6
          
          return (
            <div key={index} style={{ position: 'relative', width: `10${increaseBy}%`, marginLeft: '-13px', }}>
            <label>{type}</label>
            <AceEditorComponent code={code} type={type} />
            {RenderCopyToClipboardButtonLightMode(code, isCopied, setIsCopied, copyToClipboard)}
        </div>
          );
        } else {
          return (
            <pre>{segment}</pre>
          );
        }
    }
    
    function renderTextWithInlineCode(text) {
        const inlineCodePattern = /`([^`]+)`/g;
        const brPattern = /<br\s*\/?>/gi;
        const h3Pattern = /^### (.*$)/gm;
        const h2Pattern = /^## (.*$)/gm;
        const h1Pattern = /^# (.*$)/gm;
        const boldPattern = /\*\*([^*]+)\*\*/g;
        const italicPattern = /(?<!\*)\*([^*]+)\*(?!\*)/g; // This makes sure it's surrounded by single asterisks only.
        const blockquotePattern = /^>>\s?(.*)$/gm;

        let match;
        const elements = [];
        let lastIndex = 0;
    
        // Function to handle unmatched text segments
        const handleUnmatchedSegment = (start, end) => {
            let segment = text.substring(start, end);
    
            // Handle <br> tags
            segment = segment.split(brPattern).join('\n');
    
            // Handle markdown style headings
            segment = segment.replace(h3Pattern, '<h3>$1</h3>');
            segment = segment.replace(h2Pattern, '<h2>$1</h2>');
            segment = segment.replace(h1Pattern, '<h1>$1</h1>');
    
            // Handle markdown bold and italic
            segment = segment.replace(boldPattern, '<strong>$1</strong>');
            segment = segment.replace(italicPattern, '<em>$1</em>');
            segment = segment.replace(blockquotePattern, '<blockquote>$1</blockquote>');
    
            elements.push(<span key={start} dangerouslySetInnerHTML={{ __html: segment }} />);
        };
    
        while ((match = inlineCodePattern.exec(text)) !== null) {
            if (match.index > lastIndex) {
                handleUnmatchedSegment(lastIndex, match.index);
            }
            elements.push(
                <code key={match.index}>{match[1]}</code>
            );
            lastIndex = inlineCodePattern.lastIndex;
        }
        if (lastIndex < text.length) {
            handleUnmatchedSegment(lastIndex, text.length);
        }
    
        return elements;
    }
    
    function processContent(segment) {
        // Handle <br> tags
        let processedSegment = segment.split(/<br\s*\/?>/gi).join('\n');
    
        // Handle markdown style headings
        processedSegment = processedSegment.replace(/^### (.*$)/gm, '<h3>$1</h3>');
        processedSegment = processedSegment.replace(/^## (.*$)/gm, '<h2>$1</h2>');
        processedSegment = processedSegment.replace(/^# (.*$)/gm, '<h1>$1</h1>');
    
        return processedSegment;
    }

    // Split content based on your requirements
    const segments = content.split(/(```[\s\S]*?```)/);
    const handleBackgroundClick = () => {
        setIsEditing(false);
        onClose();
    }
    

    return (
        <StyledModal onClick={handleBackgroundClick}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()} style={{ ...styles.body }}>
           <div className="modal-header"> 
            <div className="modal-header-info">
                <pre><span><b>Item: {itemId} </b></span></pre>
                <pre><span><b>Created:</b> {createdDate}</span></pre>
                <pre><span><b>Name:</b> {name}</span></pre>
                <pre><b>Data:</b></pre>
            </div>
            <button style={{ ...styles.bttn, maxWidth: "100px", marginTop: "0px"}} onClick={handleBackgroundClick}>Close</button>
            </div>
            <div className="modal-body" > {/* style={{ marginTop: '-30px'}} */}
            {!isEditing ? (
                <div>
                <div style={{ position: "relative" }}>
                  <FaEdit onClick={toggleEdit} style={{ cursor: 'pointer', position: 'absolute', float: 'top', right: "20px", top: "0px"  }} />
                  </div>
                  <div style={{ marginTop: "10px"}}> 
                {
                    segments.map((segment, index) => {
                        if (/^```[\s\S]*```$/.test(segment)) {
                        // Code block handling remains the same...
                        return renderSegment(segment, index);
                        } else if (/`[^`]+`/.test(segment)) {
                        // Inline code block handling...
                        return <pre key={index}>{renderTextWithInlineCode(segment)}</pre>;
                        } else {
                        // Here, we apply the `dangerouslySetInnerHTML` property with processed content
                        return (
                            <div 
                            key={index} 
                            className="text-content" // Ensure this class has the appropriate CSS applied
                            dangerouslySetInnerHTML={{ __html: processContent(segment) }}
                            />
                        );
                        }
                    })
                }
                </div>
              </div>
            ) : (
            <div className="textarea-container"  style={{ position: 'relative', paddingBottom: '20px'}}>
                <FaTimes onClick={toggleEdit} style={{ cursor: 'pointer', float: 'right' }} />
                <StyledTextarea
                ref={textareaRef}
                value={editableContent} 
                onChange={handleContentChange}
                style={{ ...styles.textarea }}
                />
                <button onClick={saveContent}><FaSave /> Save</button>
                <div className="loading-spinner" id="loading-spinner" style={{ ...styles.loadingSpinner, display: isLoading ? 'block' : 'none', paddingLeft: '20px'  }}>
                    <svg viewBox="0 0 50 50" style={styles.loadingSpinnerSvg}>
                        <circle cx="25" cy="25" r="20" stroke="#007aff" strokeWidth="5" fill="none" style={styles.loadingSpinnerCircle} />
                    </svg>
                </div>
              </div>
            )}
            </div>
          </div>
        </StyledModal>
      );      
}

export default Modal