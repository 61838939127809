import React, { useState, useRef, useEffect, useCallback } from "react";
import './Dashboard.css';
import { DataContainer } from "./dataWrapper.js";
import JSZip from 'jszip';
import { setCookie, getCookie } from '../cookieUtils.js';
import VectorVault from 'vectorvault'
import { debounce } from 'lodash';
import AddToVaultSection from "../components/AddToVault.js";

function Database({ Ampersandposium, setAmpersandposium, userEmail, userKey, styles, isDarkMode, AIKeyVal, setAIKeyVal, wait, isMobile}) {
  const ChatContainerRef = useRef(null); // create a reference using the useRef hook
  const [notificationMessage, setNotificationMessage] = useState('');
  const [notificationShown, setNotificationShown] = useState(false);
  const [chatHistory, setChatHistory] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [inputValue, setInputValue] = useState('demo');
  const [siteUrl, setSiteUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [customDataVisible, setCustomDataVisible] = useState(false);
  const [customData, setCustomData] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showDelete, setShowDelete] = useState(true);
  const [inputCode, setInputCode] = useState('');
  const [gpt4, setGpt4] = useState(false);
  const [display, setDisplay] = useState(true);
  const [database, setDatabase] = useState(true);
  const [reloadData, setReloadData] = useState(false);
  const [vaults, setVaults] = useState(() => {
  const storedVaults = getCookie('vaults');
    try {
      return storedVaults ? JSON.parse(storedVaults) : ['-'];
    } catch (e) {
      console.error("Error parsing vaults from cookie:", e);
      return ['-'];
    }
  });
  const [newVaultName, setNewVaultName] = useState(''); // useState for new vault name
  const [showCreateNewInput, setShowCreateNewInput] = useState(false);


  const fetchVaults = useCallback(async () => {
    if (!userKey) return;

    const vectorVault = new VectorVault(userEmail, Ampersandposium, userKey);
    const vaults = await vectorVault.getVaults('');
    if (vaults.length === 0 || vaults[0] !== '-') {
      setVaults(['-', 'Create New', ...vaults]);
      setCookie('vaults', JSON.stringify(['-', 'Create New', ...vaults]));
    } else {
      setVaults(vaults);
      setCookie('vaults', JSON.stringify(vaults));
    }
  }, [userEmail, Ampersandposium, userKey]);

  useEffect(() => {
    if (!wait && userKey) {
      const storedVaults = getCookie("vaults");
      if (storedVaults) {
        setVaults(JSON.parse(storedVaults));
      }
      fetchVaults();
    }
  }, [wait, userKey, fetchVaults]);

  // useEffect to update cookie when vaults list changes
  useEffect(() => {
    setCookie("vaults", JSON.stringify(vaults));
  }, [vaults]);

  // Handler for selecting a vault
  const handleVaultChange = (e) => {
    const value = e.target.value;
    if (value === 'Create New') {
      setShowCreateNewInput(true);
      setNewVaultName('');
    } else {
      setShowCreateNewInput(false);
      updateAmpersandposium(value); 
    }
  };


  // Handler for creating a new vault
  const handleCreateNewVault = () => {
    if (newVaultName) {
      const updatedVaults = [...vaults, newVaultName];
      setVaults(updatedVaults);
      updateAmpersandposium(newVaultName); 
      setShowCreateNewInput(false);
    } else {
      alert("Please enter a name for the new vault.");
    }
  };
  
  
  // Handle window resize
  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (reloadData && !wait && Ampersandposium) {
      updateAmpersandposium(Ampersandposium);
    }
  }, [reloadData, wait, Ampersandposium]);

  // Load Ampersandposium from cookie on component mount
  useEffect(() => {
    const storedAmpersan = getCookie("Ampersandposium");
    if (storedAmpersan && !wait) {
      updateAmpersandposium(storedAmpersan)
    }
  }, [wait]);

  const updateAmpersandposium = debounce((value) => {
    setAmpersandposium(value);
    setReloadData(prevState => !prevState); // Reload data
    setCookie("Ampersandposium", value);
  }, 0); 

  const Notification = ({ message, notificationShown, setNotificationShown }) => {
    const [isVisible, setIsVisible] = useState(true);
  
    useEffect(() => {
      if (notificationShown) {
        const timeoutId = setTimeout(() => {
          setIsVisible(false);
          setNotificationShown(false); // Reset the notificationShown state when the notification is hidden
        }, 5000);
  
        return () => {
          clearTimeout(timeoutId);
        };
      }
    }, [notificationShown, setNotificationShown]);
  
    if (!isVisible || !notificationShown) {
      return null;
    }
  
    return (
      <div style={styles.notificationStyle}>
        {message}
      </div>
    );
  };

  
  async function extractTextFromDocx(arrayBuffer) {
      const zip = new JSZip();
      const content = await zip.loadAsync(arrayBuffer);
      const docXML = await content.files["word/document.xml"].async("text");
      const parser = new DOMParser();
      const docNode = parser.parseFromString(docXML, "application/xml");

      const nsResolver = (prefix) => {
          return {
              'w': 'http://schemas.openxmlformats.org/wordprocessingml/2006/main'
          }[prefix] || null;
      };

      const textNodeResults = docNode.evaluate('//w:t', docNode, nsResolver, XPathResult.ORDERED_NODE_SNAPSHOT_TYPE, null);

      let textContentArray = [];
      for (let i = 0; i < textNodeResults.snapshotLength; i++) {
          textContentArray.push(textNodeResults.snapshotItem(i).textContent);
      }
      
      return textContentArray.join(" ");
  }

  async function handleFileUpload(event) {
      const file = event.target.files[0];
      console.log('file type:', file.type)
      setIsLoading(true);

      const reader = new FileReader();

      reader.onload = async function(event) {
        let fileContent;

        switch(file.type) {
            case 'text/plain': // .txt
            case 'text/csv':   // .csv
            case 'text/markdown': // .md
              fileContent = event.target.result;
              break;
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': // .docx
                fileContent = await extractTextFromDocx(event.target.result);
                break;
            default:
                setIsLoading(false);
                alert("Unsupported file type.");
                return;
        }

        if (checkAIKey()) {
          const vectorVault = new VectorVault(userEmail, Ampersandposium, userKey, AIKeyVal);
          await vectorVault.addCloud({ text : fileContent, name: file.name })
        .then(response => response.json())
        .then(data => {
            console.log(data);
        })
        .catch(error => {
            console.error("Error during fetch:", error);
        })
        .finally(() => {
            event.target.value = null;
            setIsLoading(false); // Hide spinner
            setReloadData(prevState => !prevState); // Reload data
            if (file.type.startsWith('image/')){
              alert("Text successfully extracted.")
            } else {
              alert("File uploaded successfully.")
            }
        });
      }
    };
    // Determine how to read the file based on its type
    if (file.type === 'text/plain' || file.type === 'text/csv' || file.type === 'text/markdown') {
        reader.readAsText(file);
    } else if (file.type === 'application/pdf' || 
               file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
               file.type.startsWith('image/')) {  // For all image types, including heic, jpeg, png
        reader.readAsArrayBuffer(file);
    } else {
        setIsLoading(false);
        alert("Unsupported file type.");
    }
  };

  
  async function handleCustomDataSave(customDataContent) {
      setIsLoading(true); 
      if (checkAIKey()) {
        const vectorVault = new VectorVault(userEmail, Ampersandposium, userKey, AIKeyVal);
        await vectorVault.addCloud({ text : customDataContent })
      .catch(error => {
          console.error("There was an error adding your data to the cloud:", error);
      })
      .finally(() => {
          setIsLoading(false); // Hide spinner when the fetch is complete
          setCustomData('')
          setReloadData(prevState => !prevState); // Reload data
          alert("Data uploaded successfully.")
      });
    }
  }

  function checkAIKey() {
      if (!AIKeyVal || AIKeyVal.trim() === '') {
          alert("OpenAI API key required to make request");
          return false;
      }
      return true;
  }

  
  // Update handleSiteUpload to accept siteUrl as a parameter
  async function handleSiteUpload(siteUrl) {
    setIsLoading(true);
    if (checkAIKey()) {
      const vectorVault = new VectorVault(userEmail, Ampersandposium, userKey, AIKeyVal);
      await vectorVault.addSite({ site: siteUrl, name: siteUrl })
        .then(response => response.json())
        .then(data => {
          console.log(data);
        })
        .catch(error => {
          console.error("There was an error with the fetch operation:", error);
        })
        .finally(() => {
          setIsLoading(false);
          setReloadData(prevState => !prevState);
          alert("Site uploaded successfully.");
        });
    }
  }


  const handleDelete = () => {
    setShowModal(true); // Show the modal when the delete button is clicked
  }

  async function confirmDeletion() {
    if (inputCode === "DELETE") {
      setShowModal(false);
      setIsLoading(true); // Show spinner
      const vectorVault = new VectorVault(userEmail, Ampersandposium, userKey);
      try {
        const response = await vectorVault.delete();
        console.log('delete response', response);
        updateAmpersandposium(vaults[0])
        fetchVaults()
        alert("Database deleted successfully.");
      } catch (error) {
        console.error("There was an error with the fetch operation:", error);
        alert("Error deleting database.");
      } finally {
        setIsLoading(false); // Hide spinner
        setReloadData(prevState => !prevState); // Reload data
        setInputCode(''); // Reset the input code
      }
    } else {
      alert('You must type "DELETE" to confirm you want to delete the entire database');
      setInputCode(''); // Reset the input for another try
    }
  }



  const handleInputSubmit = () => {
    setAmpersandposium(inputValue); 
    updateAmpersandposium(inputValue); 
    setReloadData(prevState => !prevState); // Reload data
    console.log('Vault updated to:', inputValue);
  };
  
  // Load the state from local storage when the component mounts
  useEffect(() => {
    const storedChatHistory = localStorage.getItem("chatHistory") || "[]";
    // Load the chat history from local storage
    try {
      const parsedChatHistory = JSON.parse(storedChatHistory);
      setChatHistory(parsedChatHistory);
    } catch (error) {
      console.error("Failed to parse stored chat history:", error);
    }
  }, []);

  document.addEventListener("touchstart", function() {
    if (document.body.style.zoom !== "1") {
      document.body.style.zoom = 1;
    }
  });

  return (
    <div style={styles.wrapper}>
        <div className="App" style={{...styles.body, paddingTop: "50px"}}>
          <div style={{marginTop:"20px"}}>
            {display && (
              <>
                {!showDelete && ( <> <div style={{marginTop: "-40px"}}></div> </>)}
                {showDelete && (
                  <>
                  <div style={{marginTop: "-10px"}}></div>
                    {database && (
                      <>
                      <div style={{ marginTop: '15px' }}></div>
                      <h3 style={{marginBottom: "20px"}}>Vault: {Ampersandposium}</h3>
                        {showCreateNewInput ? (
                          <div>
                            <input
                              type="text"
                              className={isDarkMode ? 'sleek-input-dark' : 'sleek-input-light'}
                              value={newVaultName}
                              onChange={(e) => setNewVaultName(e.target.value)}
                              placeholder="Enter new vault name"
                            />
                            <button style={{ ...styles.btn }} onClick={handleCreateNewVault}>Create</button>
                          </div>
                    ) : (
                      <>
                        <select
                          className={isDarkMode ? 'sleek-select-dark' : 'sleek-select-light'}
                          value={Ampersandposium}
                          onChange={handleVaultChange}
                        >
                          {Array.isArray(vaults) && vaults.map(vault => (
                            <option key={vault} value={vault}>{vault}</option>
                          ))}
                        </select>
                        <button style={{ ...styles.btn, marginLeft: "-5px" }} onClick={() => handleDelete()}>Delete</button>
                      </>
                    )}
              
                    <div style={{ marginTop: "20px" }}>
                      <AddToVaultSection 
                        isDarkMode={isDarkMode}
                        handleFileUpload={handleFileUpload}
                        handleSiteUpload={handleSiteUpload}
                        handleCustomDataSave={handleCustomDataSave}
                        isMobile={isMobile}
                        styles={styles}
                      />
                    </div>

                  </>
                )}
                {/* Loading Spinner */}
                <div className="loading-spinner" style={{ display: isLoading ? 'block' : 'none' }}>
                  <svg viewBox="0 0 50 50">
                    <circle cx="25" cy="25" r="20" stroke="#007aff" strokeWidth="5" fill="none" />
                  </svg>
                </div>
                <div>
                    {showModal && (
                        <div className="modal">
                            <div className="modal-content" style={{paddingLeft: "15px", paddingTop: "10px"}}>
                                <a>Delete every item in the database? Enter 789 to proceed:</a><br />
                                <input 
                                    type="text" 
                                    value={inputCode}
                                    onChange={(e) => setInputCode(e.target.value)}
                                />
                                <button style={{marginTop: "10px"}} onClick={confirmDeletion}>Confirm</button>
                                <button onClick={() => setShowModal(false)}>Cancel</button>
                                <br /><br />
                            </div>
                        </div>
                    )}
                    {customDataVisible && (
                      <>
                        <textarea
                            placeholder={`Type or paste something you want to add to the database...`}
                            id="chat-message"
                            rows={isMobile ? 12 : 7}
                            style={{ ...styles.textarea, marginTop: "25px", paddingRight: "6px", paddingLeft: "8px"}}
                            value={customData}
                            onChange={(e) => setCustomData(e.target.value)}
                        />
                        <button style={{ ...styles.button, marginTop: "0px", marginLeft: "0px" }} onClick={() => handleCustomDataSave(customData)}>Save to database </button>
                      </>
                    )}
                    </div>
                    <br />
                  </>
                )}
              </>
            )}
        </div>
      {notificationMessage && (
        <div style={{marginTop: "10px"}}>
        <Notification
          message={notificationMessage}
          notificationShown={notificationShown}
          setNotificationShown={setNotificationShown}
        />
        </div>
      )}

      <DataContainer
        isDarkMode={isDarkMode}
        ref={ChatContainerRef} // pass the reference as a prop
        isMobile={isMobile}
        chatHistory={chatHistory}
        setChatHistory={setChatHistory}
        windowWidth={windowWidth}
        Ampersandposium={Ampersandposium} // vault
        display={display}
        reloadData={reloadData}
        showDelete={showDelete}
        userEmail={userEmail} // email
        userKey={userKey} // api
        AIKeyVal={AIKeyVal} // api
        setAIKeyVal={setAIKeyVal}
        wait={wait}
        styles={styles}
      />
    </div>
  </div>
  );
}

export default Database;



