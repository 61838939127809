import React, { useEffect, useRef } from 'react';

const IframeFlowBuilder = ({ userEmail, userKey, AIKeyVal }) => {
    const iframeRef = useRef(null);
    const isIframeLoaded = useRef(false);

    // Effect to attach the load event listener
    useEffect(() => {
      const handleLoad = () => {
          isIframeLoaded.current = true;
      };
      
      const iframe = iframeRef.current;
      iframe.addEventListener('load', handleLoad);
      
      return () => {
          iframe.removeEventListener('load', handleLoad);
      };
  }, []);
  
  // Effect to send the message when the data is ready
  useEffect(() => {
    if (isIframeLoaded.current && AIKeyVal) {
      const data = {
        userEmail,
        userKey,
        AIKeyVal
      };
      iframeRef.current.contentWindow.postMessage(data, '*');
    }
  }, [userEmail, userKey, AIKeyVal]); // Depend on the data

    
    useEffect(() => {
      const iframe = iframeRef.current;
      let iframeLoaded = false;

      const handleLoad = () => {
          iframeLoaded = true;
          sendDataToIframe();
      };

      const sendDataToIframe = () => {
          if (iframeLoaded && iframe.contentWindow && userEmail && userKey && AIKeyVal) {
              const data = { userEmail, userKey, AIKeyVal };
              iframe.contentWindow.postMessage(data, '*');
          }
      };

      iframe.addEventListener('load', handleLoad);

      // Set up a listener for messages from the iframe
      const handleMessage = (event) => {
          if (event.data === 'iframeReady') {
              sendDataToIframe();
          }
      };
      window.addEventListener('message', handleMessage);

      // Attempt to send data periodically in case the iframe is already loaded
      const dataCheckInterval = setInterval(sendDataToIframe, 1000);

      return () => {
          iframe.removeEventListener('load', handleLoad);
          window.removeEventListener('message', handleMessage);
          clearInterval(dataCheckInterval);
      };
  }, [userEmail, userKey, AIKeyVal]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <iframe
        ref={iframeRef}
        src="/FlowBuilder.html"
        title="Vector Flow"
        style={{ flex: 1, border: 'none' }}
      />
    </div>
  );
};

export default IframeFlowBuilder;