// vaultData.js
export const getAccountData = async (email, api) => {
  try {
      const data = { user: email, api_key: api };
      const response = await fetch('https://api.vectorvault.io/get_vault_data', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
      });

      const res = await response.json();
      return res.vault_data;
  } catch (error) {
      console.error('Error:', error);
  }
};
