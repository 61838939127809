import React from 'react';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '../useMediaQuery';
import logo from '../Vector Vault Icon.png';
import { lightStyles } from '../styles';

const LoginForm = ({ form, handleChange, handleSubmit, loading, error }) => {
  const isMobile = useMediaQuery(768 * 2);
  const navigate = useNavigate();

  const handleRegisterClick = () => {
    navigate('/register');
  };
  return (
    <div style={{ ...lightStyles.body }}>
        <h1 style={{ ...lightStyles.h1, textAlign: "left", fontSize: isMobile ? "2.8rem" : "3.5rem", display: "flex", alignItems: "left", marginTop: isMobile ? "0px" : "8px"}}>
          <img src={logo} className="App-logo" alt="logo" style={{height: isMobile ? "4rem" : "4.5rem", marginRight: "1rem", marginTop: isMobile ? "-6px" : "-3px"}} />
          Vector Vault
        </h1>
    <div className="row justify-content-center">
      <div className="col-12 col-md-8 col-lg-6">
        <div className="form-container mt-5 p-4 rounded bg-white shadow">
          <h2 className="text-center mb-4">Login</h2>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label>Email:</label>
              <input 
                type="email" 
                className="form-control" 
                name="email" 
                value={form.email} 
                onChange={handleChange} 
                placeholder="Enter your email" 
              />
            </div>
            <br />
            <div className="form-group">
              <label>Password:</label>
              <input 
                type="password" 
                className="form-control" 
                name="password" 
                value={form.password} 
                onChange={handleChange} 
                placeholder="Enter your password" 
              />
            </div>
            <br />
            <button type="submit" style={{ ...lightStyles.button }} disabled={loading}>Login</button>
          {/* Link to go to the registration page */}
          <button type="button" className="btn btn-link btn-block" onClick={handleRegisterClick}>  Register</button>
          </form>
          {loading && <div className="alert alert-info" style={{ marginTop: '20px' }}>Loading...</div>}
          {error && <div className="alert alert-danger" style={{ marginTop: '20px' }}>An error occurred: {error}</div>}
        </div>
        <br /><br /><br /><br /><br />
      </div>
    </div>
    </div> 
  );
};

export default LoginForm;