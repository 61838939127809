// App.js
import React, { useState, createContext, useEffect, useCallback } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { setCookie, getCookie, removeCookie } from './cookieUtils'; // Import the utility functions
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import useMediaQuery from './useMediaQuery.js';
import Footer from './components/Footer';
import Sidebar from './components/Sidebar'; 
import LoginForm from './components/LoginForm'; 
import RegistrationForm from './components/RegisterForm';
import APIKeys from './components/APIKeys.js';
import Usage from './components/Usage';
import Settings from './components/Settings.js';
import Dashboard from './dashboard/Dashboard'; 
import Vaults from './components/VaultTable.js';
import Deploy from './components/Deploy.js';
import IframeFlowBuilder from './components/FlowBuilder/flowBuilderPage.js';
import { registerUser } from './api/registerUser';
import { loginUser } from './api/loginUser';
import { postMail } from './api/credationStation';
import useTagManager from './hooks/useTagManager';
import { darkStyles, lightStyles } from './styles.js';
import Database from './dashboard/Database.js';
import debounce from 'lodash.debounce';
import { secureSessionRetrieve } from './api/secureStore.js';
// import FlowBuilder from './components/FlowBuilder/FlowBuilder.html';

const AuthContext = createContext(null);

function App() {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [registrationForm, setRegistrationForm] = useState({ first: '', last: '', email: '', password: '' });
  const [loginForm, setLoginForm] = useState({ email: '', password: '' });
  const [isAuthenticated, setIsAuthenticated] = useState(getCookie('isAuth') === 'true');
  const [userEmail, setUserEmail] = useState(getCookie('userEmail'));
  const breakpoint = 700 * 2;
  const [isMobile, setIsMobile] = useState(useMediaQuery(breakpoint));
  const [userKey, setUserKey] = useState(false);
  const [userPlan, setUserPlan] = useState(false);
  const [customerID, setCustomerID] = useState(false);
  const [FirstName, setFirstName] = useState(false);
  const [LastName, setLastName] = useState(false);
  const [uuid, setUuid] = useState(false);
  const [pass, setPass] = useState(false);
  const [requests, setRequests] = useState(false);
  const [depsBrah, setDepsBrah] = useState(false);
  const [error, setError] = useState(null);
  const [Ampersandposium, setAmpersandposium] = useState(false)
  const [loading, setLoading] = useState(false);
  const [AIKeyVal, setAIKeyVal] = useState('');
  const [sidebarVisible, setSidebarVisible] = useState(getCookie('sidebarVisible') !== null ? getCookie('sidebarVisible') === 'true' : true); 
  const [wait, setWait] = useState(true); 
  const [displayValue, setDisplayValue] = useState('');
  const styles = isDarkMode ? darkStyles : lightStyles;
  
  // Debounced resize handler
  const handleResize = useCallback(debounce(() => {
    setIsMobile(window.innerWidth * window.devicePixelRatio <= breakpoint);
  }, 25), []); // 25ms debounce time

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  useTagManager();

  useEffect(() => {
    const validateUser = () => {
      let email = getCookie('userEmail');
      if (email) {
        email = email.toLowerCase();
      }
      if (!email || email.trim() === '') {
        handleLogout();
      } else {
        handleCredGet();
      }
    };
  
    validateUser();
  }, [userEmail,]);

  const handleRegistrationChange = (event) => {
    setRegistrationForm({
      ...registrationForm,
      [event.target.name]: event.target.value,
    });
  };

  const handleLoginChange = (event) => {
    setLoginForm({
      ...loginForm,
      [event.target.name]: event.target.value,
    });
  };

  const handleLogout = () => {
    removeCookie('userEmail');
    removeCookie('Ampersandposium');
    removeCookie('isAuth');
    setIsAuthenticated(false);
  };
  const handleCredGet = async () => {
    try {
      if (!userEmail || userEmail.trim() === '') {
        console.log(userEmail);
        return;
      }
      setWait(true);
      const result = await postMail(userEmail.toLowerCase());
      setIsAuthenticated(true);
      setCookie('isAuth', true);
      setUserKey(result.key);
      setUserPlan(result.plan);
      setCustomerID(result.id);
      setFirstName(result.fname);
      setLastName(result.lname);
      setPass(result.password);
      setRequests(result.requests);
      setUuid(result.uuid);
      setDepsBrah(result.deployments);
      // console.log(depsBrah)
    } catch (error) {
      console.error('Error:', error);
      handleLogout();
    } finally {
      setAmpersandposium(getCookie('Ampersandposium') || '');
    }
  };
  
  useEffect(() => {
    if (userKey) {
        setWait(false);
    }
  }, [userKey]);

  useEffect(() => {
    const fetchAIKey = async () => {
      try {
        const aiKey = await secureSessionRetrieve(userEmail);
        if (aiKey) {
          setAIKeyVal(aiKey);
          setDisplayValue('*'.repeat(aiKey.length));
        }
      } catch (error) {
        console.error('Error fetching AI key:', error);
      }
    };
  
    const fetchData = async () => {
      if (getCookie('isAuth') !== 'true') {
        handleLogout();
      } else {
        try {
          await Promise.all([handleCredGet(), fetchAIKey()]);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
    };
    
    fetchData();
    setWait(false);

  }, []);

  const handleRegistrationSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    setError(null);

    registerUser(registrationForm)
      .then(response => {
        if (response.status === 'success') {
          setIsAuthenticated(true);
          setCookie('isAuth', true);
          setUserEmail(loginForm.email);
          setCookie('userEmail', loginForm.email);
            handleCredGet();
        } else {
          setError(response.message);
        }
      })
      .catch(error => setError(error.message))
      .finally(() => setLoading(false));
  };

  const handleLoginSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    setError(null);
  
    loginUser(loginForm)
      .then(result => {
        if (result.success) {
          setIsAuthenticated(true);
          setUserEmail(loginForm.email);
          setCookie('userEmail', loginForm.email);
          console.log('login success')
              handleCredGet();
        } else {
          setError(result.message || 'Login failed');
        }
      })
      .catch(error => {
        setError(error.message || 'An error occurred during login');
      })
      .finally(() => setLoading(false));
  };  

  return (
    <div className="App" style={{ display: 'flex', flexDirection: isAuthenticated ? 'row' : 'column', height: '100vh' }}>
      <BrowserRouter>
        {isAuthenticated && <Sidebar setIsDarkMode={setIsDarkMode} isDarkMode={isDarkMode} styles={styles} userEmail={userEmail} sidebarVisible={sidebarVisible} setSidebarVisible={setSidebarVisible} customerID={customerID} setIsAuthenticated={setIsAuthenticated} isMobile={isMobile} />} 
          <div style={{ flex: 1, overflowY: 'auto' /* Allows Sidebar */ }}>
            <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated }}>
                <div className="Content"> 
                  <Routes>
                    <Route path="/login" element={!isAuthenticated ? <LoginForm form={loginForm} handleChange={handleLoginChange} handleSubmit={handleLoginSubmit} loading={loading} error={error} /> : <Navigate to="/dashboard" />} />
                    <Route path="/register" element={!isAuthenticated ? <RegistrationForm form={registrationForm} handleChange={handleRegistrationChange} handleSubmit={handleRegistrationSubmit} loading={loading} error={error} /> : <Navigate to="/dashboard" />} />
                    <Route path="/deployments" element={isAuthenticated ? <Deploy isDarkMode={isDarkMode} userEmail={userEmail} styles={styles} uuid={uuid} depsBrah={depsBrah} wait={wait} userPlan={userPlan} userKey={userKey} /> : <Navigate to="/login" />} />
                    <Route path="/database" element={isAuthenticated ? <Database Ampersandposium={Ampersandposium} setAmpersandposium={setAmpersandposium} userEmail={userEmail} userKey={userKey} isDarkMode={isDarkMode} styles={styles} AIKeyVal={AIKeyVal} setAIKeyVal={setAIKeyVal} wait={wait} isMobile={isMobile} /> : <Navigate to="/login" />} />
                    <Route path="/dashboard" element={isAuthenticated ? <Dashboard Ampersandposium={Ampersandposium} setAmpersandposium={setAmpersandposium} userEmail={userEmail} userKey={userKey} isDarkMode={isDarkMode} styles={styles} AIKeyVal={AIKeyVal} setAIKeyVal={setAIKeyVal} wait={wait} isMobile={isMobile} displayValue={displayValue} setDisplayValue={setDisplayValue} /> : <Navigate to="/register" />} />
                    <Route path="/vector-flow" element={isAuthenticated ? <IframeFlowBuilder userEmail={userEmail} userKey={userKey} AIKeyVal={AIKeyVal} /> : <Navigate to="/login" />} />
                    <Route path="/vaults" element={isAuthenticated ? <Vaults userEmail={userEmail} userKey={userKey} isDarkMode={isDarkMode} wait={wait} styles={styles} /> : <Navigate to="/login" />} />
                    <Route path="/" element={<Navigate to={isAuthenticated ? "/dashboard" : "/register"} />} />
                    <Route path="*" element={<Navigate to={isAuthenticated ? "/dashboard" : "/register"} />} />
                    <Route path="/apikeys" element={isAuthenticated ? <APIKeys isDarkMode={isDarkMode} sidebarVisible={sidebarVisible} userEmail={userEmail} api={userKey} pass={pass} setLoading={setLoading} loading={loading} /> : <Navigate to="/login" />} />
                    <Route path="/usage" element={isAuthenticated ? <Usage isDarkMode={isDarkMode} usage={requests} plan={userPlan} handleCredGet={handleCredGet} userEmail={userEmail} userKey={userKey} wait={wait} /> : <Navigate to="/login" />} />
                    <Route path="/settings" element={isAuthenticated ? <Settings isDarkMode={isDarkMode} userEmail={userEmail} FirstName={FirstName} LastName={LastName} handleLogout={handleLogout} userPlan={userPlan} uuid={uuid} setUuid={setUuid} /> : <Navigate to="/login" />} />
                  </Routes> 
                </div>
            </AuthContext.Provider>
          </div>
        </BrowserRouter>
      {!isAuthenticated && <Footer style={{ flex: 1 }} />}
    </div> 
  );
}

export default App;
