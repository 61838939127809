/** loginUser.js
 * logs in a user with the given form data.
 *
 * @param {Object} formData The data from the registration form.
 * @returns {Promise} A promise that resolves if registration is successful and rejects if an error occurs.
 */
export const loginUser = (formData) => {
  const url = 'https://register.vectorvault.io/login';
  const headers = new Headers({
    'Authorization': 'expected_authorization_code',
    'Content-Type': 'application/json'
  });

  return fetch(url, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(formData),
  })
  .then(response => response.json())
  .then(data => {
    if (data.status === 'success') {
      return { success: true };
    } else {
      return { success: false, message: data.message };
    }
  })
  .catch(error => {
    console.error('Login error:', error);
    return { success: false, message: 'Network or other error' };
  });
};

  